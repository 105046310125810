import React from 'react';
import parser from 'html-react-parser';
import python_workshop from  './python_workshop_01.png';
import python_icon from './python-icon.png';

const PythonWorkshop = ({ heading, course }) => {
    return (<>
        <h1>{ heading }</h1>
        <div className="flex-container">
            <div className="flex-item-large">
                <h3>{course.title}</h3>
                <p>
                    <a 
                        href={course.url}
                        rel="noreferrer">
                        <img className="pythonlogo" src={python_icon} alt="PYTHON" />
                        REGISTER NOW
                    </a> 
                </p>

                <div className="description">
                    { parser(course.description) }
                </div>
                <div>
                    <strong>Workshop fee:</strong> {course.fee}
                </div>
            </div>
            <div className="flex-item-large">
                <div>
                    <a 
                        href={course.url}
                        id="advt"
                        rel="noreferrer">
                        <img
                            src={python_workshop}
                            width="546" 
                            title={course.title}
                            alt="Python Workshop"
                        />
                    </a>
                </div>
            </div>
        </div>
    </>
    )};

export default PythonWorkshop;