// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.episode-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
}
  
  
.episode-container h4 {
    display: block;
    margin-bottom: 0px;
    text-shadow: black 1px 1px 5px;  
}
 
.episode-detail {
    width: 364px;
    margin: 10px 50px;
}

.episode-detail a {
    /* display: block; */
    display: flex;
    line-height: 2em;
    background-color: lightblue;
    border: 2px solid blue;
    border-radius: 10px;
    padding: 5px;
    text-decoration: none;
}

.duration {
    margin: 0px;
    font-size: 0.9em;
}
  
.description {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/components/EpisodeVideo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI,cAAc;IACd,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,4DAA4D;IAC5D,gBAAgB;AACpB","sourcesContent":[".episode-container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    background-color: white;\n    border-radius: 10px;\n    margin-bottom: 10px;\n}\n  \n  \n.episode-container h4 {\n    display: block;\n    margin-bottom: 0px;\n    text-shadow: black 1px 1px 5px;  \n}\n \n.episode-detail {\n    width: 364px;\n    margin: 10px 50px;\n}\n\n.episode-detail a {\n    /* display: block; */\n    display: flex;\n    line-height: 2em;\n    background-color: lightblue;\n    border: 2px solid blue;\n    border-radius: 10px;\n    padding: 5px;\n    text-decoration: none;\n}\n\n.duration {\n    margin: 0px;\n    font-size: 0.9em;\n}\n  \n.description {\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    font-size: 0.8em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
